import React, { Component } from "react";
import moment from "moment";
import { getI18n } from "react-i18next";

export default class Invoice extends Component {
  constructor(props) {
    super(props);
    this._ = getI18n();
    this.state = {
      order: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    let { order } = nextProps;
    if (order) {
      this.setState({ order });
    }
  }

  render() {
    const { order } = this.state;
    let supplements = [];
    let options = [];
    console.log(order);
    return order ? (
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="invoice-title">
                    <h4 class="float-right font-size-16">
                      <strong>
                        {this._.t("ORDER")} # {order.reference}
                      </strong>
                    </h4>
                    <h3 class="mt-0">
                      <img
                        src="/assets/images/logo.png"
                        alt="logo"
                        height="32"
                      />
                    </h3>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-6">
                      <address>
                        <strong>{this._.t("CLIENT_INFO")}:</strong>
                        <br />
                        {order.client.firstName} {order.client.lastName}
                        <br />
                        {order.client.email
                          ? `Email: ${order.client.email}`
                          : "Email: -"}
                        <br />
                        {order.client.phone
                          ? `Tel: ${order.client.phone}`
                          : "Tel: -"}
                        <br />
                      </address>
                    </div>
                    <div class="col-6 text-right">
                      {order.pickup ? (
                        <div />
                      ) : (
                        <address>
                          <strong>{this._.t("DELIVERY_ADDRESS")}</strong>
                          <br />
                          {order.address}
                          <br />
                          {order.city} {order.country}
                          <br />
                          {order.zipCode}
                          <br />
                        </address>
                      )}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 mt-4">
                      <address>
                        <strong>{this._.t("PAYMENT_METHOD")}:</strong>
                        <br />
                        {order.paymentGateway}
                        <br/>
                        {order.paymentGateway === 'Paiement en ligne' ? `status : ${order.transaction ? order.transaction.status : 'En cours'}` : ''}
                      </address>
                      {order.justificatif ? (
                        <address>
                          <strong>{this._.t("JUSTIFICATIF")}</strong>
                          <br />
                        </address>
                      ) : (
                        <div />
                      )}
                      <address>
                        <strong>{this._.t("CLIENT_INFO")}:</strong>
                        <br />
                        {order.remarque}
                      </address>
                    </div>
                    <div class="col-6 mt-4 text-right">
                      <address>
                        <strong>{this._.t("DELIVERY_DATE")}:</strong>
                        <br />
                        {moment(order.date).format("llll")}
                        <br />
                        <br />
                      </address>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div>
                    <div class="p-2">
                      <h3 class="font-size-16">
                        <strong>{this._.t("PRODUCT_LIST")}</strong>
                      </h3>
                    </div>
                    <div class="">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <td>
                                <strong>{this._.t("PRODUCT")}</strong>
                              </td>
                              <td class="text-center">
                                <strong>{this._.t("PRICE")}</strong>
                              </td>
                              <td class="text-center">
                                <strong>{this._.t("PRODUCT_OPTIONS")}</strong>
                              </td>

                              <td class="text-center">
                                <strong>
                                  {this._.t("PRODUCT_SUPPLEMENTS")}
                                </strong>
                              </td>

                              <td class="text-center">
                                <strong>{this._.t("QUANTITY")}</strong>
                              </td>
                              <td class="text-right">
                                <strong>{this._.t("TOTAL")}</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {order.content.map((content) => {
                              let total = 0;
                              content.selectedOptions.forEach((option) => {
                                option.items.forEach(
                                  (item) => (total += item.price)
                                );
                              });
                              content.selectedSupplements.forEach(
                                (supplement) => {
                                  supplement.items.forEach(
                                    (item) => (total += item.price)
                                  );
                                }
                              );
                              return (
                                <tr>
                                  <td>{content.product.name}<br/><span style={{color: 'rgb(162, 162, 162)'}}>{content.instructions}</span></td>
                                  <td class="text-center">
                                    {content.product.price}€
                                  </td>
                                  <td>
                                    {content.selectedOptions.map((elem) => {
                                      return (
                                        <>
                                          {`${elem.name}:  ${elem.items.map(
                                            (item) => item.name
                                          )} `}
                                          <br />
                                        </>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {content.selectedSupplements.map((elem) => {
                                      return (
                                        <>
                                          {`${elem.name}:  ${elem.items.map(
                                            (item) => item.name
                                          )} `}
                                          <br />
                                        </>
                                      );
                                    })}
                                  </td>

                                  <td class="text-center">x{content.qte}</td>
                                  <td class="text-right">
                                    {(content.product.price + total) *
                                      content.qte}
                                    €
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td class="no-line"></td>
                              <td class="no-line"></td>
                              <td class="no-line text-center">
                                <strong>{this._.t("TOTAL")}</strong>
                              </td>
                              <td class="no-line text-right">
                                <h4 class="m-0">{order.total}€</h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="d-print-none">
                        <div class="float-right">
                          <a
                            href="javascript:window.print()"
                            class="btn btn-success waves-effect waves-light"
                          >
                            <i class="fa fa-print"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  }
}
