const baseURL = "//api.latableduboulangeraubagne.fr/";
//const baseURL = "http://localhost:5002/";
const staticURL = "//api.latableduboulangeraubagne.fr/";
//const staticURL = "http://localhost:5002/";

exports.ROUTER = {
  RESET: "/reset",
  LOGIN: "/login",
  HOME: "/home",
  CATEGORIES: {
    LIST: "/category",
    CREATE: "/category/new",
    EDIT: (id) => `/category/edit/${id}`,
  },
  PRODUCTS: {
    LIST: "/products",
    CREATE: "/products/new",
    EDIT: (id) => `/products/edit/${id}`,
  },
  OPTIONS: {
    LIST: "/options",
    CREATE: "/options/new",
    EDIT: (id) => `/options/edit/${id}`,
  },
  SUPPLEMENTS: {
    LIST: "/supplements",
    CREATE: "/supplements/new",
    EDIT: (id) => `/supplements/edit/${id}`,
  },
  MENUS: {
    LIST: "/menus",
    CREATE: "/menus/new",
    EDIT: (id) => `/menus/edit/${id}`,
  },
  USERS: {
    LIST: "/user",
    CREATE: "/user/new",
    EDIT: (id) => `/user/edit/${id}`,
  },
  ORDERS: {
    LIST: "/orders",
    ONHOLD: "/orders/waiting",
    INVOICE: (id) => `/order/invoice/${id}`,
  },
  MESSAGES: {
    LIST: "/messages",
  },
  SETTINGS: "/settings",
  DELIVERY: "/delivery",
  CONTENT: "/content",
  ERROR: "/404",
};
exports.STATIC = {
  LOGO: "/assets/images/logo.png",
  LOGO_WHITE: "/assets/images/logo_white.jpg",
  ICON: "/assets/images/icon.png",
  TITLE: "La table du boulanger aubagne",
  ROUTE: (file) =>
    `${staticURL}${file.startsWith("/") ? file.substring(1) : file}`,
};
exports.API = {
  auth: {
    login: `${baseURL}admin/v1/auth/login`,
  },
  me: `${baseURL}admin/v1/me/`,
  category: {
    create: `${baseURL}admin/v1/category/new`,
    all: `${baseURL}admin/v1/category/all`,
    list: (page, count, name) =>
      `${baseURL}admin/v1/category/list?page=${page}&count=${count}&name=${name}`,
    remove: `${baseURL}admin/v1/category/delete`,
    info: (id) => `${baseURL}admin/v1/category/info/${id}`,
    update: (id) => `${baseURL}admin/v1/category/edit/${id}`,
  },
  product: {
    create: `${baseURL}admin/v1/product/new`,
    all: `${baseURL}admin/v1/product/all`,
    list: (page, count, name) =>
      `${baseURL}admin/v1/product/list?page=${page}&count=${count}&name=${name}`,
    remove: `${baseURL}admin/v1/product/delete`,
    info: (id) => `${baseURL}admin/v1/product/info/${id}`,
    update: (id) => `${baseURL}admin/v1/product/edit/${id}`,
  },
  menu: {
    publishWeek: `${baseURL}admin/v1/menu/publish`,
    product: `${baseURL}admin/v1/menu/product`,
    getWeek: `${baseURL}admin/v1/menu/week`,
  },
  messages: {
    list: (page, count) =>
      `${baseURL}admin/v1/message/list?page=${page}&count=${count}`,
    remove: `${baseURL}admin/v1/message/delete`,
  },
  user: {
    create: `${baseURL}admin/v1/user/new`,
    all: `${baseURL}admin/v1/user/all`,
    list: (page, count, name) =>
      `${baseURL}admin/v1/user/list?page=${page}&count=${count}&name=${name}`,
    remove: `${baseURL}admin/v1/user/delete`,
    info: (id) => `${baseURL}admin/v1/user/info/${id}`,
    update: (id) => `${baseURL}admin/v1/user/edit/${id}`,
  },

  settings: {
    load: `${baseURL}admin/v1/settings/load`,
    update: `${baseURL}admin/v1/settings/update`,
  },
  content: {
    load: `${baseURL}admin/v1/pages/load`,
    update: `${baseURL}admin/v1/pages/update`,
  },
  delivery: {
    load: `${baseURL}admin/v1/delivery/load`,
    update: `${baseURL}admin/v1/delivery/update`,
  },
  orders: {
    info: (id) => `${baseURL}admin/v1/order/info/${id}`,
    list: (page, count) =>
      `${baseURL}admin/v1/order/list?page=${page}&count=${count}`,
    onHold: (page, count) =>
      `${baseURL}admin/v1/order/hold?page=${page}&count=${count}`,
    validate: `${baseURL}admin/v1/order/validate`,
    reject: `${baseURL}admin/v1/order/reject`,
  },
  option: {
    create: `${baseURL}admin/v1/option/new`,
    all: `${baseURL}admin/v1/option/all`,
    list: (page, count, name) =>
      `${baseURL}admin/v1/option/list?page=${page}&count=${count}&name=${name}`,
    remove: `${baseURL}admin/v1/option/delete`,
    info: (id) => `${baseURL}admin/v1/option/info/${id}`,
    update: (id) => `${baseURL}admin/v1/option/edit/${id}`,
  },
  supplement: {
    create: `${baseURL}admin/v1/supplement/new`,
    all: `${baseURL}admin/v1/supplement/all`,
    list: (page, count, name) =>
      `${baseURL}admin/v1/supplement/list?page=${page}&count=${count}&name=${name}`,
    remove: `${baseURL}admin/v1/supplement/delete`,
    info: (id) => `${baseURL}admin/v1/supplement/info/${id}`,
    update: (id) => `${baseURL}admin/v1/supplement/edit/${id}`,
  },
};
exports.REACT_APP_GOOGLE_API_KEY = "AIzaSyCDJ_8QPJUFbK_v_CAeY3J1T9ehvMoJq2A";

//exports.REACT_APP_GOOGLE_API_KEY = "AIzaSyCep1aEs90BdL71_GmqJi5bHQgBaZDWHg4";

exports.position = {
  latitude: 48.8566,
  longtitude: 2.3522,
};
