import React, { Component } from 'react'
import Invoice from '../../components/Invoice/Invoice'
import { getI18n } from 'react-i18next'
import { API, ROUTER } from '../../env'

export default class InvoiceOrder extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        id: this.props.match.params.id,
        order: null,
      }
      this._ = getI18n();
    }
    componentDidMount(){
        fetch(API.orders.info(this.state.id), {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            }
        })
            .then(res=>res.json())
            .then(response => {
                if(response.error) {
                    window.location = ROUTER.ORDERS.LIST;
                }
                this.setState({
                    order: response,
                });
            })
            .catch(e => window.location = ROUTER.ORDERS.LIST);
    }
    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">{this._.t('ORDER_INFORMATION')}</h4>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                </div>
                <Invoice order={this.state.order}/>
            </div>
        )
    }
}
