import React, { Component } from "react";
import EditForm from "../../components/Supplement/EditForm";
import { API, ROUTER } from "../../env";
import swal from "sweetalert";
import { getI18n } from "react-i18next";

export default class EditSupplement extends Component {
  constructor(props) {
    super(props);
    this._ = getI18n();
    this.state = {
      //  id: this.props.option ? this.props.option._id : '',
      id: this.props.match.params.id,
      items: [],
      itemsError: "",
      name:  "",
      nameError: '',
      description: "",
      descriptionError: "",
      idItem: 0,
      editMode: false,
      currentEditElement: "",
      nameItem: "",
      nameItemError: "",
      priceItem: null,
      priceItemError: "",

    };
    this.update = this.update.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addItem = this.addItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
  }

  componentWillMount() {
    fetch(API.supplement.info(this.state.id), {
      method: "GET",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((supplement) =>
      supplement.error
          ? (window.location = ROUTER.ERROR)
          : this.setState(
              {
                name: supplement.name,
                items: supplement.items ? supplement.items : [],
                id: supplement._id,
                description: supplement.description,
              }
            )
      )
      .catch((error) => swal("Error", error.message, "error"));
  }

  handleChange(event) {
    const state = {};
    state[event.target.id] = event.target.value;
    this.setState(state);
  }

 
 validate(){
    let isError = false;
    const errors = {
      nameError: '',
      descriptionError: "",
      itemsError: "",
      nameItemError: '',
      priceItemError: "",

    }
    if (this.state.name === "") {
      isError = true;
      errors.nameError = this._.t("NAME_ERROR");
    }
    if (this.state.description === "") {
      isError = true;
      errors.descriptionError = this._.t("DESCRIPTION_ERROR");
    }
    console.log(this.state.items.length)
    if(this.state.items.length === 0){
      console.log('jaw jaw ')
      isError = true;
      errors.itemsError = this._.t("ITEMS_ERROR");
    }

  
    this.setState({
      ...this.state,
      ...errors
    });

    return isError;
  }
 

  validateItem(){
    let isError = false;
    const errors = {
      nameItemError: '',
      priceItemError: "",
      nameError: '',
      descriptionError: "",
      itemsError: ""

    }
    if (this.state.nameItem === "") {
      isError = true;
      errors.nameItemError = this._.t("NAME_ITEM_ERROR");
    }
    
  
    this.setState({
      ...this.state,
      ...errors
    });

    return isError;
  }
  update() {
      const err = this.validate();
      if(!err){
        this.setState({
          nameError: '',
          descriptionError: "",
          itemsError: "",
          nameItemError: '',
          priceItemError: "",
        })
        const ValueItems = []
        this.state.items.forEach(elem => {
          ValueItems.push({
            name: elem.name,
            price: elem.price
          })
        })
        const data = {
            name: this.state.name,
            description: this.state.description,
            items: ValueItems,
          };
          fetch(API.supplement.update(this.state.id), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": localStorage.getItem("token"),
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((response) => {
              if (response.error) {
                return swal(this._.t("ERROR"), response.message, "error");
              }
              return swal(
                this._.t("SUCCESS"),
                this._.t("DATA_SUPPLEMENT_SAVED"),
                "success"
              ).then(() => {
                window.location = ROUTER.SUPPLEMENTS.LIST;
              });
            })
            .catch((e) =>
              swal(this._.t("ERROR"), this._.t("DATA_SUPPLEMENT_ERR"), "error")
            );
      }
    
  }
  addItem(id) {
    const err = this.validateItem();
    if(!err){
      this.setState({
        nameItemError: '',
        itemsError: "",
        nameError: '',
        descriptionError: "",

      
      })
        if (this.state.editMode === true) {
            let itemsToChange = this.state.items;
            const findIndex = itemsToChange.findIndex(
              (x) => x._id === this.state.currentEditElement
            );
            const currentId = this.state.currentEditElement;
            itemsToChange[findIndex] = {
              _id: currentId,
              name: this.state.nameItem,
              price: this.state.priceItem === '' || this.state.priceItem === '0' || this.state.priceItem === null ? 0: this.state.priceItem
            };
      
            this.setState({
              items: itemsToChange,
              nameItem: "",
              priceItem: 0,
              editMode: false,
            });
          } else {
            const id = this.state.idItem + 1;
            const newItem = {
              _id: id,
              name: this.state.nameItem,
              price: this.state.priceItem === '' || this.state.priceItem === '0' || this.state.priceItem === null ? 0: this.state.priceItem,
            };
            this.setState((prevState) => ({
              items: [...prevState.items, newItem],
              idItem: this.state.idItem + 1,
            }));
            this.setState({
              nameItem: "",
              priceItem: null,
            });
          }
      
          
          window.document.getElementById("nameItem").value = "";
          window.document.getElementById("priceItem").value = "";
    }
    
  }
  editItem(index) {   
    const editedElement = this.state.items[index];
    const name = editedElement.name;
    const price = editedElement.price;
    const id = editedElement._id;
    this.setState({
      nameItem: name,
      priceItem: price,
      editMode: true,
      currentEditElement: id,
    });

  }
  deleteItem(index) {
    let itemsToChange = [...this.state.items];
    itemsToChange.splice(index, 1);
    this.setState((prevState) => ({
      items: [...itemsToChange],
    }));
  }
  handleChangeDescription(description){
      this.setState({
          description
      })

  }
  render() {
    const i18n = getI18n();
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">{i18n.t("SUPPLEMENT")}</h4>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">
                    {i18n.t("EDIT_SUPPLEMENT_HEADER")}
                  </li>
                </ol>
              </div>
              <div className="col-sm-6">
              <div className="form-group mb-0" style={{ float: 'right' }}>
                                    <div>
                                        <button type="reset" className="btn btn-secondary waves-effect  mr-1" onClick={() => window.location = ROUTER.SUPPLEMENTS.LIST}>{i18n.t('CANCEL')}</button>
                                        <button type="submit" className="btn btn-primary waves-effect waves-light mr-1" 
                                    
                                       onClick={
                                            this.update
                                        }
                                        >{i18n.t('UPDATE')
                                            }</button>
                                    </div>
            </div>



              </div>
            </div>
          </div>
          <EditForm 
            state={this.state} 
            update = {this.update}
            addItem = {this.addItem}
            deleteItem = {this.deleteItem}
            editItem = {this.editItem}
            handleChange = {this.handleChange}
            handleChangeDescription = {this.handleChangeDescription}

          
          
          />
        </div>
      </div>
    );
  }
}
