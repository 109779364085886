import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { getI18n } from "react-i18next";
import Footer from "../Footer/Footer";
import SideBar from "../SideBar/SideBar";
import TopBar from "../TopBar/TopBar";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import withAuth from "../../withAuth";
import NotFound from "../../pages/NotFound";
import { ROUTER } from "../../env";

import AddCategory from "../../pages/Categories/AddCategory";
import ListCategories from "../../pages/Categories/ListCategories";
import EditCategory from "../../pages/Categories/EditCategory";

import AddProduct from "../../pages/Products/AddProduct";
import ListProducts from "../../pages/Products/ListProducts";
import EditProduct from "../../pages/Products/EditProduct";

import AddOption from "../../pages/Options/AddOption";
import ListOptions from "../../pages/Options/ListOptions";
import EditOption from "../../pages/Options/EditOption";

import AddSupplement from "../../pages/Supplement/AddSupplement";
import ListSupplements from "../../pages/Supplement/ListSupplements";
import EditSupplement from "../../pages/Supplement/EditSupplement";

import ListMenus from "../../pages/TodayMenu/ListMenus";
import Settings from "../../pages/Settings/Settings";
import InvoiceOrder from "../../pages/Orders/InvoiceOrder";
import ListOrder from "../../pages/Orders/ListOrder";
import OnHoldOrders from "../../pages/Orders/OnHoldOrders";
import ListMessage from "../../pages/Messages/ListMessage";
import Delivery from "../../pages/Delivery/Delivery";
import Content from "../../pages/Content/Content";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: localStorage.getItem("token"),
    };
  }
  render() {
    return this.state.authenticated ? (
      <div id="wrapper">
        <SideBar />
        <TopBar />
        <div className="content-page">
          <BrowserRouter forceRefresh={true}>
            <Switch>
              <Route exact path="/" component={withAuth(Home)} />
              <Route exact path={ROUTER.HOME} component={withAuth(Home)} />

              <Route
                exact
                path={ROUTER.CATEGORIES.CREATE}
                component={withAuth(AddCategory)}
              />
              <Route
                exact
                path={ROUTER.CATEGORIES.EDIT(":id")}
                component={withAuth(EditCategory)}
              />
              <Route
                exact
                path={ROUTER.CATEGORIES.LIST}
                component={withAuth(ListCategories)}
              />

              <Route
                exact
                path={ROUTER.PRODUCTS.CREATE}
                component={withAuth(AddProduct)}
              />
              <Route
                exact
                path={ROUTER.PRODUCTS.EDIT(":id")}
                component={withAuth(EditProduct)}
              />
              <Route
                exact
                path={ROUTER.PRODUCTS.LIST}
                component={withAuth(ListProducts)}
              />

              <Route
                exact
                path={ROUTER.OPTIONS.CREATE}
                component={withAuth(AddOption)}
              />
              <Route
                exact
                path={ROUTER.OPTIONS.EDIT(":id")}
                component={withAuth(EditOption)}
              />
              <Route
                exact
                path={ROUTER.OPTIONS.LIST}
                component={withAuth(ListOptions)}
              />

              <Route
                exact
                path={ROUTER.SUPPLEMENTS.CREATE}
                component={withAuth(AddSupplement)}
              />
              <Route
                exact
                path={ROUTER.SUPPLEMENTS.EDIT(":id")}
                component={withAuth(EditSupplement)}
              />
              <Route
                exact
                path={ROUTER.SUPPLEMENTS.LIST}
                component={withAuth(ListSupplements)}
              />

              <Route
                exact
                path={ROUTER.MENUS.LIST}
                component={withAuth(ListMenus)}
              />

              <Route
                exact
                path={ROUTER.SETTINGS}
                component={withAuth(Settings)}
              />
              <Route
                exact
                path={ROUTER.CONTENT}
                component={withAuth(Content)}
              />

              <Route
                exact
                path={ROUTER.DELIVERY}
                component={withAuth(Delivery)}
              />

              <Route
                exact
                path={ROUTER.ORDERS.INVOICE(":id")}
                component={withAuth(InvoiceOrder)}
              />
              <Route
                exact
                path={ROUTER.ORDERS.LIST}
                component={withAuth(ListOrder)}
              />
              <Route
                exact
                path={ROUTER.ORDERS.ONHOLD}
                component={withAuth(OnHoldOrders)}
              />

              <Route
                exact
                path={ROUTER.MESSAGES.LIST}
                component={withAuth(ListMessage)}
              />

              <Route exact path="/404" component={withAuth(NotFound)} />
            </Switch>
          </BrowserRouter>
          <Footer />
        </div>
      </div>
    ) : (
      <div>
        <div className="accountbg"></div>
        <BrowserRouter forceRefresh={true}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route render={() => <Redirect to="/login" />} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
