import React, { useState, useEffect } from "react";
import { getI18n } from "react-i18next";
import { useForm } from "react-hook-form";
import { API, STATIC } from "../../env";
import swal from "sweetalert";

function RegisterPage() {
  const _ = getI18n();
  const { register, handleSubmit, errors, reset, getValues } = useForm();
  const [data, setData] = useState({});
  const [imgData, setImgData] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    window.$(".filestyle").filestyle({
      buttonName: "btn-primary",
    });
  }, []);
  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "RESET",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          console.log(response);
          setData(response);
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  const onSubmit = (dataToSend) => {
    const formData = new FormData();
    formData.append("name", "RESET");
    if (getValues("image").length > 0) {
      formData.append("files", dataToSend.image[0], "image");
    }
    if (getValues("image").length === 0) {
      if (data.image !== "") {
        dataToSend.image = data.image;
      } else {
        dataToSend.image = "";
      }
    }
    formData.append("content", JSON.stringify(dataToSend));

    fetch(API.content.update, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal(_.t("ERROR"), response.message, "error");
        }
        return swal(_.t("SUCCESS"), _.t("DATA_UPDATED"), "success");
      })
      .catch((e) => swal(_.t("ERROR"), _.t("SERVER_ERR"), "error"));
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("TITLE")}*
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={_.t("TITLE_PH")}
                      ref={register({ required: true })}
                      name="title"
                      defaultValue={data.title ? data.title : ""}
                    />
                    {errors.title && (
                      <span className="text-danger">
                        {`${_.t("TITLE")} ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  {imgData !== null ? (
                    <img
                      style={{
                        width: "600px",
                        height: "300px",
                        textAlign: "center",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      src={imgData}
                    />
                  ) : (
                    data.image && (
                      <img
                        style={{
                          width: "600px",
                          height: "300px",
                          textAlign: "center",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        src={STATIC.ROUTE(data.image)}
                      />
                    )
                  )}
                </div>
                <div className="form-group row">
                  <label for="name" className="col-sm-2 col-form-label">
                    {_.t("IMAGE")}
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="file"
                      className="filestyle"
                      name="image"
                      ref={register}
                      onChange={onChangePicture}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("TITLE_IMAGE")}*
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={_.t("TITLE_IMAGE_PH")}
                      ref={register({ required: true })}
                      name="titleImage"
                      defaultValue={data.titleImage ? data.titleImage : ""}
                    />
                    {errors.titleImage && (
                      <span className="text-danger">
                        {`${_.t("TITLE_IMAGE")} ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("SUBTITLE_IMAGE")}*
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={_.t("SUBTITLE_IMAGE_PH")}
                      ref={register({ required: true })}
                      name="subTitleImage"
                      defaultValue={
                        data.subTitleImage ? data.subTitleImage : ""
                      }
                    />
                    {errors.subTitleImage && (
                      <span className="text-danger">
                        {`${_.t("SUBTITLE_IMAGE")} ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("CONTENT_BUTTON")}*
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={_.t("CONTENT_BUTTON_PH")}
                      ref={register({ required: true })}
                      name="buttonContent"
                      defaultValue={
                        data.buttonContent ? data.buttonContent : ""
                      }
                    />
                    {errors.buttonContent && (
                      <span className="text-danger">
                        {`${_.t("CONTENT_BUTTON")} ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group mb-0" style={{ float: "right" }}>
                  <div>
                    <button
                      type="reset"
                      className="btn btn-secondary waves-effect  mr-1"
                      onClick={(e) => reset(e.target)}
                    >
                      {_.t("RESET")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light mr-1"
                    >
                      {_.t("SUBMIT")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RegisterPage;
