import React, { useState } from "react";
import { API, STATIC } from "../../../env";
import { useForm } from "react-hook-form";
import { getI18n } from "react-i18next";
import swal from "sweetalert";

export default function SlidesModal(props) {
  const _ = getI18n();
  const { register, handleSubmit, errors, reset, getValues } = useForm();
  const [imgData, setImgData] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", "SLIDES");

    if (getValues("image").length > 0) {
      formData.append("files", data.image[0], "image");
    }
    if (getValues("image").length === 0) {
      if (props.currentSlide) {
        data.image = props.currentSlide.image;
      } else {
        data.image = "";
      }
    }
    if (props.currentSlide) {
      formData.append("content", JSON.stringify(data));

      formData.append("edit", true);
      formData.append("editIndex", props.currentIndex);
    } else {
      formData.append("content", JSON.stringify(data));
    }

    fetch(API.content.update, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal(_.t("ERROR"), response.message, "error");
        }
        props.setData(response);

        reset();
        props.setCurrentSlide(null);
        props.setCurrentIndex(null);
        window.$("#myModal").modal("hide");
      })
      .catch((e) => swal(_.t("ERROR"), _.t("SERVER_ERR"), "error"));
  };
  return (
    <div
      className="modal fade bd-example-modal-lg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
      id="myModal"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {_.t("ADD_SLIDE")}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                props.setCurrentSlide(null);
                props.setCurrentIndex(null);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="form-group ">
                  <label className="col-form-label">{_.t("TITLE")}*</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={_.t("TITLE_PH")}
                    ref={register({ required: true })}
                    name="title"
                    defaultValue={
                      props.currentSlide ? props.currentSlide.title : ""
                    }
                  />
                  {errors.title && (
                    <span className="text-danger">
                      {`${_.t("TITLE")} ${_.t("RQUIRED_FIELD")}`}
                    </span>
                  )}
                </div>
                <div className="form-group ">
                  <label className="col-form-label">{_.t("SUB_TITLE")}*</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={_.t("SUB_TITLE_PH")}
                    ref={register({ required: true })}
                    name="subTitle"
                    defaultValue={
                      props.currentSlide ? props.currentSlide.subTitle : ""
                    }
                  />
                  {errors.subTitle && (
                    <span className="text-danger">
                      {`${_.t("SUB_TITLE")} ${_.t("RQUIRED_FIELD")}`}
                    </span>
                  )}
                </div>
                <div className="form-group ">
                  <label className="col-form-label">{_.t("PARAGRAPH")}*</label>
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder={_.t("PARAGRAPH_PH")}
                    ref={register({ required: true })}
                    name="paragraph"
                    defaultValue={
                      props.currentSlide ? props.currentSlide.paragraph : ""
                    }
                  />
                  {errors.paragraph && (
                    <span className="text-danger">
                      {`${_.t("PARAGRAPH")} ${_.t("RQUIRED_FIELD")}`}
                    </span>
                  )}
                </div>
                <div className="form-group ">
                  <label className="col-form-label">
                    {_.t("CONTENT_BUTTON")}*
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={_.t("CONTENT_BUTTON_PH")}
                    ref={register({ required: true })}
                    name="contentButton"
                    title
                    defaultValue={
                      props.currentSlide ? props.currentSlide.contentButton : ""
                    }
                  />
                  {errors.contentButton && (
                    <span className="text-danger">
                      {`${_.t("CONTENT_BUTTON")} ${_.t("RQUIRED_FIELD")}`}
                    </span>
                  )}
                </div>
                {imgData !== null ? (
                  <img
                    style={{
                      width: "600px",
                      height: "300px",
                      textAlign: "center",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={imgData}
                  />
                ) : props.currentSlide ? (
                  <img
                    style={{
                      width: "600px",
                      height: "300px",
                      textAlign: "center",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={STATIC.ROUTE(props.currentSlide.image)}
                  />
                ) : (
                  <></>
                )}
                {}
                <div className="form-group ">
                  <label for="name" className="col-form-label">
                    {_.t("IMAGE")}
                  </label>
                  <input
                    type="file"
                    ref={register}
                    name="image"
                    className="filestyle"
                    onChange={onChangePicture}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  props.setCurrentSlide(null);
                  props.setCurrentIndex(null);
                }}
              >
                {_.t("Close")}
              </button>
              <button type="submit" className="btn btn-primary">
                {_.t("SUBMIT")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
