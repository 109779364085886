import React, { Component } from 'react'
import {ROUTER, STATIC} from '../../env';
export default class TopBar extends Component {
    componentDidMount(){
	window.$.MainApp.init();
    }
    logout(){
        localStorage.clear();
        window.location = ROUTER.LOGIN;
    }
    render() {
        return (
            <div className="topbar">
                <div className="topbar-left">
                    <a href={ROUTER.HOME} className="logo">
                        <span>
                            <img src={STATIC.LOGO} alt="Contribute Tunisia vs covid-19 logo" style={{height: 50}} />
                        </span>
                        <i>
                            <img src={STATIC.ICON} alt="Contribute Tunisia vs covid-19 small logo" style={{width: '100%', height: 'auto', padding: 10}} />
                        </i>
                    </a>
                </div>

                <nav className="navbar-custom">
                    <ul className="navbar-right list-inline float-right mb-0">
                        <li className="dropdown notification-list list-inline-item">
                            <div className="dropdown notification-list nav-pro-img">
                                <button style={{border: 'none', background: 'transparent'}} className="dropdown-toggle nav-link arrow-none waves-effect nav-user" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                    <img src="/assets/images/users/user-4.jpg" alt="user" className="rounded-circle" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                                    <button className="dropdown-item text-danger" onClick={this.logout.bind(this)}><i className="mdi mdi-power text-danger"></i> Logout</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="list-inline menu-left mb-0">
                        <li className="float-left">
                            <button className="button-menu-mobile open-left waves-effect">
                                <i className="mdi mdi-menu"></i>
                            </button>
                        </li>
                    </ul>

                </nav>

            </div>

        )
    }
}
