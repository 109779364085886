import React, { Component } from "react";
import TimePicker from "react-times";
import { API } from "../../env";
// use material theme
import "react-times/css/material/default.css";
// or you can use classic theme
import "react-times/css/classic/default.css";
import i18next from "i18next";
import { getI18n } from "react-i18next";
import swal from "sweetalert";
import moment from "moment";
import Delivery from "../../components/Delivery";
import Modal from "../../components/Modal/Modal";
import Backdrop from "../../components/Backdrop/index";
export default class DeliveryPage extends Component {
  constructor(props) {
    super(props);
    this._ = getI18n();
    this.state = {
      active: false,
      showModal: false,
      showBackdrop: false,
      price: 0,
      planning: [
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
      ],
      currentIndex: 0,
      zoneDistance: 2,
      lat: "",
      lng: "",
    };
    this.handleShowModal = this.handleShowModal.bind(this);
    this.onSumbit = this.onSumbit.bind(this);
  }
  handleShowModal = (day, index) => {
    this.setState({
      showModal: !this.state.showModal,
      showBackdrop: !this.state.showBackdrop,
      currentDay: day,
      currentIndex: index,
    });
  };
  handleCheckClick = async () => {
    await this.setState({ active: !this.state.active });
    await fetch(API.settings.update, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "DELIVERY",
        value: {
          active: this.state.active,
          price: this.state.price,
          zoneDistance: this.state.zoneDistance,
          planning: this.state.planning,
        },
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        swal(this._.t("SUCCESS"), this._.t("DATA_UPDATED"), "success");
      })
      .catch((e) => {});
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    this.setState({
      [name]: value,
    });
  };
  onSumbit(startTime, endTime, elemIndex) {
    this.setState((prevState) => {
      return {
        ...prevState,

        planning: prevState.planning.map((item, index) =>
          index === elemIndex
            ? { ...item, from: startTime, to: endTime }
            : // otherwise return original todo
              item
        ),
      };
    });
    this.handleShowModal();
  }
  update() {
    fetch(API.settings.update, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "DELIVERY",
        value: {
          active: this.state.active,
          price: this.state.price,
          zoneDistance: this.state.zoneDistance ? Number.parseFloat(this.state.zoneDistance) : 0,
          planning: this.state.planning,
        },
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal(this._.t("ERROR"), response.message, "error");
        }
        swal(this._.t("SUCCESS"), this._.t("DATA_UPDATED"), "success");
      })
      .catch((e) => {});
  }
  componentDidMount() {
    fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "DELIVERY",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.setState(response);
      })
      .catch((e) => {});

    fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "GENERAL",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          lat: response.location.lat,
          lng: response.location.lng,
        });
      })
      .catch((e) => {});
  }
  render() {
    return (
      <>
        <div className="content">
          {this.state.showModal && (
            <Modal
              day={this.state.currentDay}
              index={this.state.currentIndex}
              closeModal={this.handleShowModal}
              onSumbit={this.onSumbit.bind(this)}
            />
          )}
          {this.state.showBackdrop && (
            <Backdrop backdropClick={this.handleShowModal} />
          )}
          <Delivery
            state={this.state}
            update={this.update.bind(this)}
            handleChange={this.handleChange.bind(this)}
            handleCheckClick={this.handleCheckClick.bind(this)}
            handleShowModal={this.handleShowModal.bind(this)}
          />
        </div>
      </>
    );
  }
}
