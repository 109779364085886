import React, { Component } from 'react'
import { ROUTER } from '../env'
import { getI18n } from 'react-i18next'

export default class Home extends Component {
  render() {
      const _ = getI18n();
    return (
      <section class="mt-5 mb-5">
            <div class="container-alt container">
                <div class="row">
                    <div class="col-12 text-center" style={{color: 'rgb(17, 22, 58)'}}>
                        <div class="home-wrapper m-t-40">
                            <div class="mb-4">
                            </div>

                            <div class="maintenance-img">
                                <img src="/assets/images/maintenance.png" alt="" class="img-fluid mx-auto d-block"/>
                            </div>
    <h3 class="m-t-30">{_.t('WELCOME_TO')}</h3>

                            <div class="row">
                                <div class="text-center col-md-4" style={{cursor: 'pointer'}} onClick={() => window.location = ROUTER.PRODUCTS.LIST}>
                                    <div class="card mt-4 maintenance-box">
                                        <div class="card-body">
                                            <i style={{color: '#bacf45'}}class="mdi mdi-bowl m-b-15"></i>
                                            <h6 class="text-uppercase">{_.t('MANAGE_PRODUCT')}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center col-md-4" style={{cursor: 'pointer'}} onClick={() => window.location = ROUTER.HOME}>
                                    <div class="card mt-4 maintenance-box">
                                        <div class="card-body">
                                            <i style={{color: '#bacf45'}} class="mdi mdi-cash-multiple m-b-15"></i>
                                            <h6  class="text-uppercase">{_.t('MANAGE_ORDERDS')}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center col-md-4" style={{cursor: 'pointer'}} onClick={() => window.location = ROUTER.MENUS.LIST}>
                                    <div class="card mt-4 maintenance-box">
                                        <div class="card-body">
                                            <i style={{color: '#bacf45'}} class="mdi mdi-calendar m-b-15"></i>
                                            <h6 class="text-uppercase">{_.t('MANAGE_MENU')}</h6>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
  }
}
