import React, { Component } from "react";
import AddForm from "../../components/Supplement/AddForm";
import { getI18n } from "react-i18next";
import { ROUTER } from "../../env";
import { API } from "../../env";
import swal from "sweetalert";

export default class AddSupplement extends Component {
  constructor(props) {
    super(props);
    this._ = getI18n();
    this.state = {
      name: "",
      nameError: '',
      description: "",
      descriptionError: "",
      items: [],
      itemsError: "",
      idItem: 0,
      nameItem: "",
      nameItemError: "",
      priceItem: null,
      priceItemError: "",
      editMode: false,
      currentEditElement: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.create = this.create.bind(this);
    this.addItem = this.addItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.nameItem = React.createRef();
    this.priceItem = React.createRef();
  }


  validate(){
    let isError = false;
    const errors = {
      nameError: '',
      descriptionError: "",
      itemsError: "",
      nameItemError: '',
      priceItemError: "",

    }
    if (this.state.name === "") {
      isError = true;
      errors.nameError = this._.t("NAME_ERROR");
    }
    if (this.state.description === "") {
      isError = true;
      errors.descriptionError = this._.t("DESCRIPTION_ERROR");
    }
    console.log(this.state.items.length)
    if(this.state.items.length === 0){
      console.log('jaw jaw ')
      isError = true;
      errors.itemsError = this._.t("ITEMS_ERROR");
    }

  
    this.setState({
      ...this.state,
      ...errors
    });

    return isError;
  }
 

  validateItem(){
    let isError = false;
    const errors = {
      nameItemError: '',
      priceItemError: "",
      nameError: '',
      descriptionError: "",
      itemsError: ""

    }
    if (this.state.nameItem === "") {
      isError = true;
      errors.nameItemError = this._.t("NAME_ITEM_ERROR");
    }
    if (this.state.priceItem === null) {
      isError = true;
      errors.priceItemError = this._.t("PRICE_ITEM_ERROR");
    }
  
  
    this.setState({
      ...this.state,
      ...errors
    });

    return isError;
  }
  create() {
    const err = this.validate();
    if(!err){
      this.setState({
        nameError: '',
        descriptionError: "",
        itemsError: "",
        nameItemError: '',
        priceItemError: "",
      })
      const data = {
        name: this.state.name,
        description: this.state.description,
        items: this.state.items,
      };
  
      fetch(API.supplement.create, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal(this._.t("ERROR"), response.message, "error");
          }
          return swal(
            this._.t("SUCCESS"),
            this._.t("DATA_SUPPLEMENT_SAVED"),
            "success"
          ).then(() => {
            window.location = ROUTER.SUPPLEMENTS.LIST;
          });
        })
        .catch((e) =>
          swal(this._.t("ERROR"), this._.t("DATA_SUPPLEMENT_ERR"), "error")
        );
    }
    
  }
  addItem() {
    const err = this.validateItem();
    if(!err){
      this.setState({
        nameItemError: '',
        priceItemError: "",
        itemsError: "",
        nameError: '',
        descriptionError: "",

      
      })
      if (this.state.editMode === true) {
        let itemsToChange = this.state.items;
        const findIndex = itemsToChange.findIndex(
          (x) => x.id === this.state.currentEditElement
        );
        const currentId = this.state.currentEditElement;
        itemsToChange[findIndex] = {
          id: currentId,
          name: this.state.nameItem,
          price: this.state.priceItem === '' || this.state.priceItem === '0' || this.state.priceItem === null ? 0: this.state.priceItem,
        };
  
        this.setState({
          items: itemsToChange,
          nameItem: "",
          priceItem: null,
          editMode: false,
        });
      } else {
        const id = this.state.idItem + 1;
        const newItem = {
          id: id,
          name: this.state.nameItem,
          price: this.state.priceItem === '' || this.state.priceItem === '0' || this.state.priceItem === null ? 0: this.state.priceItem,
        };
        this.setState((prevState) => ({
          items: [...prevState.items, newItem],
          idItem: this.state.idItem + 1,
        }));
        this.setState({
          nameItem: "",
          priceItem: null,
        });
      }
  
      
      window.document.getElementById("nameItem").value = "";
      window.document.getElementById("priceItem").value = "";
    }
    
  }
  editItem(index) {
    
    const editedElement = this.state.items[index];
    const name = editedElement.name;
    const price = editedElement.price;
    const id = editedElement.id;
    this.setState({
      nameItem: name,
      priceItem: price,
      editMode: true,
      currentEditElement: id,
    });

    
  }
  deleteItem(index) {
    let itemsToChange = [...this.state.items];
    itemsToChange.splice(index, 1);
    this.setState((prevState) => ({
      items: [...itemsToChange],
    }));
  }

  handleChange(event) {
    const state = {};
    state[event.target.id] = event.target.value;
    this.setState(state);
  }
  handleChangeDescription(description) {
    this.setState({
      description,
    });
  }

 
  render() {
    const i18n = getI18n();
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">{i18n.t("SUPPLEMENT")}</h4>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">
                    {i18n.t("ADD_SUPPLEMENT_HEADER")}
                  </li>
                </ol>
              </div>
              <div className="col-sm-6">
                <div className="form-group mb-0" style={{ float: "right" }}>
                  <div>
                    <button
                      type="reset"
                      className="btn btn-secondary waves-effect  mr-1"
                      onClick={() => (window.location = ROUTER.SUPPLEMENTS.LIST)}
                    >
                      {i18n.t("CANCEL")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light mr-1"
                      onClick={this.create}
                    >
                      {i18n.t("SAVE")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddForm
            state={this.state}
            handleChange={this.handleChange}
            create={this.create}
            addItem={this.addItem}
            handleChangeDescription={this.handleChangeDescription}
            nameItemRef={this.nameItem}
            priceItemRef={this.priceItem}
            editItem={this.editItem}
            deleteItem={this.deleteItem}
          />
        </div>
      </div>
    );
  }
}
