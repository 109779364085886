import React, { Component } from 'react'
import { API, ROUTER, STATIC } from '../../env'
import swal from 'sweetalert'
import Moment from 'react-moment';
import { getI18n } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';
class TableOrders extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            orders: [],
            total: 0,
            count: 5,
            totalPage: 1,
            showRejectModal: false,
            onHold: this.props.onHold !== undefined ? this.props.onHold : false,
        }
        this._ = getI18n();
    }
    componentDidMount() {
        this.loadPage();
    }
    reject(rejection){
        const {targetId} = this.state;
        this.setState({showRejectModal: false, targetId: null});
        fetch(API.orders.reject, {
            method: 'POST',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: targetId, rejection })
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                window.location.reload();
            })
            .catch(e => swal('Error', e.message, 'error'));
    }
    nextPage() {
        let { page } = this.state;
        page++;
        this.setState({ page });
        this.loadPage(page);
    }
    validate(id) {
        console.log(id);
        fetch(API.orders.validate, {
            method: 'POST',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id })
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                window.location.reload();
            })
            .catch(e => swal('Error', e.message, 'error'));
    }
    prevPage() {
        let { page } = this.state;
        page--;
        this.setState({ page });
        this.loadPage(page);
    }
    loadPage(page = 1) {
        fetch(this.state.onHold ? API.orders.onHold(page, this.state.count) : API.orders.list(page, this.state.count) , {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            },
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                this.setState(response);
            })
            .catch(e => swal('Error', e.message, 'error'));
    }
    render() {
        const renderContent = (elements) => elements.map(element => (
            <tr key={element._id}>
                <td><a href={ROUTER.ORDERS.INVOICE(element._id)}>{element.reference}</a></td>
                <td>{element.client.firstName} {element.client.lastName}<br />{element.client.email ? `Email : ${element.client.email}` : 'Email: -'}<br />{element.client.phone ? `Tel: ${element.client.phone}` : 'Tel: -'}</td>
                <td>{element.pickup ? 'Pick-up' : <p>{this._.t('DELIVER_TO')}: <br />{element.address}, {element.city}<br />{element.country} {element.zipCode}</p>}</td>
                <td>{element.total} €</td>
                <td>{
                    element.paymentGateway === 'Paiement en ligne' ? 
                    (
                        <div>
                            <p>Paiement en ligne</p>
                            <p>Status: {element.transaction ? element.transaction.status : 'En cours'}</p>
                        </div>
                    )
                    :
                    element.paymentGateway
                }</td>
                <td><Moment locale={'fr'} format='llll'>{element.date}</Moment></td>
                <td>
                    {
                        element.validated ?
                            this._.t('VALIDATED') :
                            element.rejected ?
                                this._.t('REJECTED') :
                                this._.t('WAITING')
                    }
                </td>
                <td>
                    <div className="button-items">
                        <button type="button" className="btn btn-outline-info waves-effect waves-light" onClick={() => window.location = ROUTER.ORDERS.INVOICE(element._id)}>{this._.t('SHOW_MORE_INFO')}</button><br />
                        {!element.validated && !element.rejected ?
                            <div><button type="button" className="btn btn-outline-success waves-effect waves-light" onClick={() => this.validate(element._id)}>{this._.t('ACCEPT_ORDER')}</button><br />
                                <button type="button" className="btn btn-outline-danger waves-effect waves-light" onClick={() => this.setState({ targetId: element._id, showRejectModal: true })}>{this._.t('REJECT_ORDER')}</button><br /></div>
                            :
                            <div />
                        }
                    </div>
                </td>
            </tr>
        ))
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="table-responsive">
                                    <div className="col-sm-12">
                                        <table className="table mb-0">

                                            <thead className="thead-light">
                                                <tr>
                                                    <th>{this._.t('ORDER_REFERENCE')}</th>
                                                    <th>{this._.t('ORDER_CLIENT')}</th>
                                                    <th>{this._.t('ORDER_TYPE')}</th>
                                                    <th>{this._.t('ORDER_TOTAL')}</th>
                                                    <th>{this._.t('ORDER_PAYMENT')}</th>
                                                    <th>{this._.t('ORDER_DATE')}</th>
                                                    <th>{this._.t('ORDER_STATE')}</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderContent(this.state.orders)}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="row" style={{ marginTop: 15 }}>
                                        <div className="col-sm-12 col-md-6 cold-log-8">
                                            <span>Showing : {((this.state.page - 1) * this.state.count) + this.state.orders.length} / {this.state.total} element(s) </span>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 offset-sm-0 offset-md-6 offset-lg-8">
                                            <div className="col-sm-12">
                                                <button onClick={this.prevPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page === 1}>{this._.t('PREV')}</button>
                                                <span style={{ marginLeft: 15, marginRight: 15 }}>{this.state.page} / {this.state.totalPage}</span>
                                                <button onClick={this.nextPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page >= this.state.totalPage}>{this._.t('NEXT')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SweetAlert
                 show={this.state.showRejectModal}
                 input
                 showCancel
                 cancelBtnBsStyle="default"
                 cancelBtnText={this._.t('CANCEL')}
                 confirmBtnText={this._.t('SUBMIT')}
                 title={this._.t('REJECT_REASON')}
                 placeholder={this._.t('REJECT_REASON_INPUT_PH')}
                 onConfirm={(response) => this.reject(response)}
                 onCancel={() => this.setState({ showRejectModal: false, targetId: null })}
                >
                    {this._.t('REJECT_REASON_PH')}
                </SweetAlert>
            </div>
        )
    }
}

export default TableOrders;
