import React, { Component } from "react";
import TimePicker from "react-times";
import { API } from "../../env";
// use material theme
import "react-times/css/material/default.css";
// or you can use classic theme
import "react-times/css/classic/default.css";
import i18next from "i18next";
import { getI18n } from "react-i18next";
import "./Style.css";
import swal from "sweetalert";
import moment from "moment";
import General from "../../components/General";
import ModalCommande from "../../components/ModalCommande";
import ModalOpenCloseTime from "../../components/ModalOpenCloseTime";
import Backdrop from "../../components/Backdrop/index";
import CroppedImageModal from "../../components/CroppedImageModal/index";
import CroppedImage from "../../components/CroppedImage/index";
export default class Settings extends Component {
  constructor(props) {
    super(props);
    this._ = getI18n();
    this.state = {
      name: "",
      email: "",
      phone: "",
      logo: "",
      adress: "",
      location: "",
      commandeTime: "",
      showModalCommande: false,
      showModalOpenClose: false,
      showBackdrop: false,
      planningCommade: [
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
      ],
      planningOpenClose: [
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
      ],
      currentIndex: 0,
      zoneDistance: 10000,
      lat: 48.8566,
      lng: 2.3522,
      formatedAdress: "",
      file: "",
      delivery: false,
      currentStartTime: "08:00",
      currentEndTime: "17:00",
      currentCommandeStartTime: "08:00",
      currentCommandeEndTime: "17:00",
      imagePreviewUrl: null,
      imageToCrop: null,
      imgData: null,
    };
  }
  onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({
          imgData: reader.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  handleChangeFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({
          imageToCrop: reader.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    e.target.value = null;
  };
  cropChangedHandler = (image) => {
    let reader = new FileReader();
    let file = image;

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };
  fileChange = (e) => {
    this.handleChangeFile(e);
    window.$("#croppedImageModal").modal("show");
  };
  handleShowModalCommande = (index, edit, from, to) => {
    this.setState({
      showModalCommande: !this.state.showModalCommande,
      showBackdrop: !this.state.showBackdrop,
      currentIndex: index,
    });
    if (edit === true) {
      this.setState({
        currentCommandeStartTime: from,
        currentCommandeEndTime: to,
      });
    }
  };
  handleShowModalOpenClose = (index, edit, from, to) => {
    this.setState({
      showModalOpenClose: !this.state.showModalOpenClose,
      showBackdrop: !this.state.showBackdrop,
      currentIndex: index,
    });
    if (edit === true) {
      this.setState({
        currentStartTime: from,
        currentEndTime: to,
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleFile = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  onSumbitTimeCommande(startTime, endTime, elemIndex) {
    this.setState((prevState) => {
      return {
        ...prevState,

        planningCommade: prevState.planningCommade.map((item, index) =>
          index === elemIndex
            ? { ...item, from: startTime, to: endTime }
            : // otherwise return original todo
              item
        ),
      };
    });
    this.handleShowModalCommande();
  }
  onSumbitTimeOpenClose(startTime, endTime, elemIndex) {
    this.setState((prevState) => {
      return {
        ...prevState,

        planningOpenClose: prevState.planningOpenClose.map((item, index) =>
          index === elemIndex
            ? { ...item, from: startTime, to: endTime }
            : // otherwise return original todo
              item
        ),
      };
    });
    this.handleShowModalOpenClose();
  }
  setLocation(lat, lng, formatedAdress) {
    this.setState({
      lat: lat,
      lng: lng,
      formatedAdress: formatedAdress,
      adress: formatedAdress,
    });
  }
  update() {
    let formData = new FormData();
    formData.append("key", "GENERAL");
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("phone", this.state.phone);
    formData.append("adress", this.state.adress);
    formData.append("commandeTime", this.state.commandeTime);
    formData.append("formatedAdress", this.state.formatedAdress);
    formData.append(
      "location",
      JSON.stringify({ lat: this.state.lat, lng: this.state.lng })
    );
    formData.append(
      "planningCommade",
      JSON.stringify(this.state.planningCommade)
    );
    formData.append(
      "planningOpenClose",
      JSON.stringify(this.state.planningOpenClose)
    );

    if (this.state.file !== "") {
      formData.append("file", this.state.file, this.state.file.name);
    }
    for (var value of formData.values()) {
      console.log(value);
    }
    fetch(API.settings.update, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal(this._.t("ERROR"), response.message, "error");
        }
        return swal(this._.t("SUCCESS"), this._.t("DATA_UPDATED"), "success");
      })
      .catch((e) => swal(this._.t("ERROR"), this._.t("DATA_ERR"), "error"));
  }
  componentDidMount() {
    fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "GENERAL",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        this.setState({
          name: response.name,
          adress: response.adress,
          email: response.email,
          formatedAdress: response.formatedAdress,
          logo: response.logo,
          phone: response.phone,
          planningCommade: response.planningCommade,
          planningOpenClose: response.planningOpenClose,
          lat: response.location.lat,
          lng: response.location.lng,
          commandeTime: response.commandeTime,
        });
      })
      .catch((e) => {});
    fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "DELIVERY",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        this.setState({
          zoneDistance: response.zoneDistance,
          delivery: response.active,
        });
      })
      .catch((e) => {});
  }
  handleCoordonee(formatedAdress, lat, lng) {
    this.setState({
      lat: lat,
      lng: lng,
      formatedAdress: formatedAdress,
      adress: formatedAdress,
    });
  }

  render() {
    return (
      <>
        <div className="content">
          {this.state.showModalCommande && (
            <ModalCommande
              day={this.state.currentDay}
              index={this.state.currentIndex}
              closeModal={this.handleShowModalCommande.bind(this)}
              onSumbit={this.onSumbitTimeCommande.bind(this)}
              from={this.state.currentCommandeStartTime}
              to={this.state.currentCommandeEndTime}
            />
          )}
          {this.state.showModalOpenClose && (
            <ModalOpenCloseTime
              day={this.state.currentDay}
              index={this.state.currentIndex}
              closeModal={this.handleShowModalOpenClose.bind(this)}
              onSumbit={this.onSumbitTimeOpenClose.bind(this)}
              from={this.state.currentStartTime}
              to={this.state.currentEndTime}
            />
          )}
          {this.state.showBackdrop && (
            <Backdrop backdropClick={this.handleShowModal} />
          )}
          <CroppedImageModal
            fileChangedHandler={this.cropChangedHandler}
            file={this.state.file}
          >
            <CroppedImage
              src={this.state.imageToCrop}
              setFile={(file) => this.setState({ file: file })}
              logo={true}
            />
          </CroppedImageModal>

          <General
            state={this.state}
            update={this.update.bind(this)}
            handleChange={this.handleChange.bind(this)}
            handleShowModalCommande={this.handleShowModalCommande.bind(this)}
            handleShowModalOpenClose={this.handleShowModalOpenClose.bind(this)}
            handleCoordonee={this.handleCoordonee.bind(this)}
            //handleFile={this.handleFile.bind(this)}
            handleFile={this.fileChange.bind(this)}
            setLocation={this.setLocation.bind(this)}
          />
        </div>
      </>
    );
  }
}
