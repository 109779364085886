import React, { useState, useEffect } from "react";
import { getI18n } from "react-i18next";
import { useForm } from "react-hook-form";
import { API } from "../../env";
import swal from "sweetalert";

function HomePage() {
  const _ = getI18n();
  const { register, handleSubmit, errors, reset } = useForm();
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "HOME",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          console.log(response);
          setData(response);
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  const onSubmit = (data) => {
    console.log(data);
    const formData = new FormData();
    formData.append('name', "HOME");
    formData.append('content',JSON.stringify(data) )
    fetch(API.content.update, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal(_.t("ERROR"), response.message, "error");
        }
        return swal(_.t("SUCCESS"), _.t("DATA_UPDATED"), "success");
      })
      .catch((e) => swal(_.t("ERROR"), _.t("SERVER_ERR"), "error"));
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("TITLE")}*
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={_.t("TITLE_PH")}
                      ref={register({ required: true })}
                      name="title"
                      defaultValue={data.title ? data.title : ""}
                    />
                    {errors.title && (
                      <span className="text-danger">
                        {`${_.t("TITLE")} ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("SUB_TITLE")}*
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={_.t("SUB_TITLE_PH")}
                      ref={register({ required: true })}
                      name="subTitle"
                      defaultValue={data.subTitle ? data.subTitle : ""}
                    />
                    {errors.subTitle && (
                      <span className="text-danger">
                        {`${_.t("SUB_TITLE")} ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("CONTENT_PARAGRAPHE")}*
                  </label>
                  <div className="col-sm-10">
                    <textarea
                      className="form-control"
                      placeholder={_.t("CONTENT_PARAGRAPHE_PH")}
                      ref={register({ required: true })}
                      name="contentParagraph"
                      rows="3"
                      defaultValue={
                        data.contentParagraph ? data.contentParagraph : ""
                      }
                    />
                    {errors.contentParagraph && (
                      <span className="text-danger">
                        {`${_.t("CONTENT_PARAGRAPHE")} ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>{`${_.t("STEP_TITLE")} 1`}*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`${_.t("STEP_TITLE_PH")} 1`}
                      ref={register({ required: true })}
                      name="step1Title"
                      defaultValue={data.step1Title ? data.step1Title : ""}
                    />
                    {errors.step1Title && (
                      <span className="text-danger">
                        {`${_.t("STEP_TITLE")} 1 ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-8">
                    <label>{`${_.t("STEP_CONTENT")} 1`}*</label>
                    <textarea
                      className="form-control"
                      placeholder={`${_.t("STEP_CONTENT_PH")} 1`}
                      ref={register({ required: true })}
                      name="step1Content"
                      rows="3"
                      defaultValue={data.step1Content ? data.step1Content : ""}
                    />
                    {errors.step1Content && (
                      <span className="text-danger">
                        {`${_.t("STEP_CONTENT")} 1 ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>{`${_.t("STEP_TITLE")} 2`}*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`${_.t("STEP_TITLE_PH")} 2`}
                      ref={register({ required: true })}
                      name="step2Title"
                      defaultValue={data.step2Title ? data.step2Title : ""}
                    />
                    {errors.step2Title && (
                      <span className="text-danger">
                        {`${_.t("STEP_TITLE")} 2 ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-8">
                    <label>{`${_.t("STEP_CONTENT")} 2`}*</label>
                    <textarea
                      className="form-control"
                      placeholder={`${_.t("STEP_CONTENT_PH")} 2`}
                      ref={register({ required: true })}
                      name="step2Content"
                      rows="3"
                      defaultValue={data.step2Content ? data.step2Content : ""}
                    />
                    {errors.step2Content && (
                      <span className="text-danger">
                        {`${_.t("STEP_CONTENT")} 2 ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>{`${_.t("STEP_TITLE")} 3`}*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`${_.t("STEP_TITLE_PH")} 3`}
                      ref={register({ required: true })}
                      name="step3Title"
                      defaultValue={data.step3Title ? data.step3Title : ""}
                    />
                    {errors.step3Title && (
                      <span className="text-danger">
                        {`${_.t("STEP_TITLE")} 3 ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-8">
                    <label>{`${_.t("STEP_CONTENT")} 3`}*</label>
                    <textarea
                      className="form-control"
                      placeholder={`${_.t("STEP_CONTENT_PH")} 3`}
                      ref={register({ required: true })}
                      name="step3Content"
                      rows="3"
                      defaultValue={data.step3Content ? data.step3Content : ""}
                    />
                    {errors.step3Content && (
                      <span className="text-danger">
                        {`${_.t("STEP_CONTENT")} 3 ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("CONTENT_BUTTON")}*
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={_.t("CONTENT_BUTTON_PH")}
                      ref={register({ required: true })}
                      name="buttonContent"
                      defaultValue={
                        data.buttonContent ? data.buttonContent : ""
                      }
                    />
                    {errors.buttonContent && (
                      <span className="text-danger">
                        {`${_.t("CONTENT_BUTTON")} ${_.t("RQUIRED_FIELD")}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group mb-0" style={{ float: "right" }}>
                  <div>
                    <button
                      type="reset"
                      className="btn btn-secondary waves-effect  mr-1"
                      onClick={() => reset("")}
                    >
                      {_.t("RESET")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light mr-1"
                    >
                      {_.t("SUBMIT")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HomePage;
