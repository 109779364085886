import React, { Component } from 'react'
import { API, ROUTER, STATIC } from '../../env'
import swal from 'sweetalert'
import Moment from 'react-moment';
import { getI18n } from 'react-i18next';

class TableProducts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            products: [],
            total: 0,
            count: 5,
            totalPage: 1,
        }
        this._ = getI18n();
    }
    componentDidMount() {
        this.loadPage();
    }
    nextPage() {
        let { page } = this.state;
        page++;
        this.setState({ page });
        this.loadPage(page, this.state.name);
    }
    prevPage() {
        let { page } = this.state;
        page--;
        this.setState({ page });
        this.loadPage(page, this.state.name);
    }
    loadPage(page = 1, name = '') {
        fetch(API.product.list(page, this.state.count, name), {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            },
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                this.setState({page, ...response});
            })
            .catch(e => swal('Error', e.message, 'error'));
    }
    delete(id) {
        swal({
            title: this._.t('ARE_YOU_SURE'),
            text: this._.t('DELETE_WARNING'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(API.product.remove, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-auth-token': localStorage.getItem('token'),
                        },
                        body: JSON.stringify({ id })
                    })
                        .then(res => res.json())
                        .then(response => {
                            if (response.error) {
                                return swal(this._.t('ERROR'), response.message, 'error');
                            }
                            return swal(this._.t('SUCCESS'), this._.t('DATA_REMOVED'), 'success').then(() => window.location = ROUTER.PRODUCTS.LIST);
                        })
                        .catch(e => swal(this._.t('ERROR'), e.message, 'error'))
                }
            });
    }
    filter(event) {
        let searchValue = event.target.value;
        this.loadPage(1, searchValue);
    }
    render() {
        const renderContent = (elements) => elements.map(element => 
            {
                const options= [];
                if(element.options){
                    element.options.forEach((elem, index, array )=> {
                        if (index === array.length - 1) {
                            options.push(elem.name)
    
                        }else {
                            options.push(elem.name)
    
                            options.push(' , '); 
    
                        }
                    })
                }
                const supplements= [];
                if(element.supplements){
                    element.supplements.forEach((elem, index, array )=> {
                        if (index === array.length - 1) {
                            supplements.push(elem.name)
    
                        }else {
                            supplements.push(elem.name)
    
                            supplements.push(' , '); 
    
                        }
                    })
                }
                return (
                    <tr key={element._id}>
                        <td style={{ padding: 15 }}>
                            <div style={{ height: 75 }}>
                                <img alt={`${element.name} product`} src={STATIC.ROUTE(element.picture ? element.picture : '/')} style={{ height: '100%', width: 'auto' }} />
                            </div>
                        </td>
                        <td>{element.name}</td>
                        <td>{element.price} €</td>
                        <td>{element.category ? element.category.name : '-'}</td>
                        <td>{element.options ? options : '-'}</td>
                        <td>{element.supplements ? supplements : '-'}</td>
                        <td>{element.show ?
                            <div style={{textAlign: 'center'}}><i style={{fontSize: 20, color: 'green'}} class="mdi mdi-eye-outline"></i></div> :
                            <div style={{textAlign: 'center'}}><i style={{fontSize: 20, color: 'red'}} class="mdi mdi-eye-off-outline"></i></div>
                        }</td>
                        <td>{!element.permanent ?
                            <div style={{textAlign: 'center'}}><i style={{fontSize: 20, color: 'green'}} class="mdi mdi-bookmark-check"></i></div> :
                            <div style={{textAlign: 'center'}}><i style={{fontSize: 20, color: 'red'}} class="mdi mdi-bookmark-off-outline"></i></div>
                        }</td>
                        <td><Moment locale={'fr'} fromNow>{element.created_at}</Moment></td>
                        <td>
                            <div className="button-items">
                                <button type="button" className="btn btn-outline-info waves-effect waves-light" onClick={() => window.location = ROUTER.PRODUCTS.EDIT(element._id)}>{this._.t('EDIT')}</button>
                                <button type="button" className="btn btn-outline-danger waves-effect waves-light" onClick={() => this.delete(element._id)}>{this._.t('DELETE')}</button>
                            </div>
                        </td>
                    </tr>
                )
            }
            )
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-5">
                                            <div className="form-group row">
                                                <label for="name" className="col-sm-2 col-form-label">{this._.t('SEARCH')}</label>
                                                <div className="col-sm-10">
                                                    <input className="form-control" type="text" id="name" placeholder={this._.t('SEARCH_PRODUCT')} onChange={this.filter.bind(this)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <table className="table mb-0">

                                            <thead className="thead-light">
                                                <tr>
                                                    <th>{this._.t('PRODUCT_IMAGE')}</th>
                                                    <th>{this._.t('PRODUCT_NAME')}</th>
                                                    <th>{this._.t('PRODUCT_PRICE')}</th>
                                                    <th>Categorie</th>
                                                    <th>{this._.t('PRODUCT_OPTIONS')}</th>
                                                    <th>{this._.t('PRODUCT_SUPPLEMENTS')}</th>
                                                    <th>{this._.t('PRODUCT_VISIBLE')}</th>
                                                    <th>{this._.t('PRODUCT_PERMANENT')}</th>
                                                    <th>{this._.t('CREATED_AT')}</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderContent(this.state.products)}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="row" style={{ marginTop: 15 }}>
                                        <div className="col-sm-12 col-md-6 cold-log-8">
                                            <span>Showing : {((this.state.page - 1) * this.state.count) + this.state.products.length} / {this.state.total} element(s) </span>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 offset-sm-0 offset-md-6 offset-lg-8">
                                            <div className="col-sm-12">
                                                <button onClick={this.prevPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page === 1}>{'< Previous'}</button>
                                                <span style={{ marginLeft: 15, marginRight: 15 }}>{this.state.page} / {this.state.totalPage}</span>
                                                <button onClick={this.nextPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page >= this.state.totalPage}>{'Next >'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TableProducts;