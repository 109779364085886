import React from "react";
import MapComponent from "../../components/MapComponent";
import { getI18n } from "react-i18next";

function Delivery(props) {
  const _ = getI18n();

  const days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];
  return (
    <div className="container-fluid">
      <div className="page-title-box">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <h4 className="page-title">{_.t("DELIVERY")}</h4>
            <ol className="breadcrumb"></ol>
          </div>
          <div className="col-sm-6">
            <div class="custom-control custom-switch float-right">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch1"
                checked={props.state.active}
                onChange={props.handleCheckClick}
              />
              <label class="custom-control-label" for="customSwitch1">
                {_.t("ACTIVATE_DELIVERY")}
              </label>
            </div>
          </div>
        </div>
      </div>
      {props.state.active && (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="row card-body">
                <div className="col-12">
                  <div className="form-group row">
                    <label for="name" className="col-sm-2 col-form-label">
                      {_.t("PRICE_DILEVERY")}
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="number"
                        placeholder={_.t("PRICE_DILEVERY_PH")}
                        onChange={props.handleChange}
                        name="price"
                        value={props.state.price}
                      />
                      <span className="text-danger"></span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="name" className="col-sm-2 col-form-label">
                      {_.t("DILEVERY_ZONE")}
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="number"
                        name="zoneDistance"
                        placeholder={_.t("DILEVERY_ZONE_PH")}
                        onChange={props.handleChange}
                        value={props.state.zoneDistance}
                      />
                      <span className="text-danger"></span>
                    </div>
                  </div>
                  {props.state.lat !== "" && props.state.lng !== "" ? (
                    <div style={{ width: "100%", height: "500px" }}>
                      <MapComponent
                        distance={props.state.zoneDistance * 1000}
                        lat={props.state.lat}
                        lng={props.state.lng}
                        drag={false}
                        delivery={props.state.active}
                        zoom={12}
                      />
                    </div>
                  ) : (
                      <div
                        style={{ width: "100%", height: "50px", color: "red" }}
                      >
                        {_.t("ADD_ADRESS")}
                      </div>
                    )}

                  <label class="custom-label">{_.t("DELIVERY_HOUR")} </label>
                  <div class="table-responsive-xl">
                    <table class="table">
                      <thead style={{ textAlign: "center" }}>
                        <tr>
                          {props.state.planning.map((elem, index) => {
                            return <th scope="col">{days[index]}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ textAlign: "center" }}>
                          {props.state.planning.map((elem, index) => {
                            return (
                              <td>
                                {elem.from !== "" && elem.to !== "" ? (
                                  <button
                                    onClick={() =>
                                      props.handleShowModal(elem.day, index)
                                    }
                                  >
                                    {elem.from} - {elem.to}
                                  </button>

                                ) : (
                                    <button
                                      onClick={() =>
                                        props.handleShowModal(elem.day, index)
                                      }
                                    >
                                      {_.t("ADD")}
                                    </button>
                                  )}
                              </td>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div style={{ float: "right" }}>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={props.update}
                    >
                      {_.t("SUBMIT")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Delivery;
