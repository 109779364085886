import React, { Component } from 'react'
import { getI18n } from 'react-i18next';
import swal from 'sweetalert';
import { ROUTER, STATIC, API } from '../env';

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
        }
    }
    handleChange(event) {
        let state = {}
        state[event.target.id] = event.target.value;
        this.setState(state);
    }
    login() {
        fetch(API.auth.login, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state),
        })
        .then(
            res => res.json()
        )
        .then(
            response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                localStorage.setItem('token', response.token);
                localStorage.setItem('user', JSON.stringify(response));
                window.location = ROUTER.HOME;
            }
        )
        .catch(e => {});
    }
    render() {
        const i18n = getI18n();
        return (
            <div className="wrapper-page account-page-full">
                <div className="card">
                    <div className="card-body">

                        <div className="text-center">
                            <a href={ROUTER.LOGIN} className="logo"><img src={STATIC.LOGO} height="75" alt="logo" /></a>
                        </div>

                        <div className="p-3">
                            <h4 className="font-18 m-b-5 text-center">{i18n.t('WELCOME_BACK')}</h4>
                            <p className="text-muted text-center">{i18n.t('SIGNING_TO_CONTINUE')}</p>

                            <form className="form-horizontal m-t-30">

                                <div className="form-group">
                                    <label for="username">{i18n.t('USERNAME')}</label>
                                    <input type="text" className="form-control" id="email" placeholder="Enter username" onChange={this.handleChange.bind(this)}/>
                                </div>

                                <div className="form-group">
                                    <label for="password">{i18n.t('PASSWORD')}</label>
                                    <input type="password" className="form-control" id="password" placeholder="Enter password"  onChange={this.handleChange.bind(this)}/>
                                </div>

                                <div className="form-group row m-t-20">

                                    <div className="col-sm-6 text-right">
                                        <button className="btn btn-primary w-md waves-effect waves-light" type="button" onClick={this.login.bind(this)}>{i18n.t('LOGIN')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

                <div className="m-t-40 text-center">
                    <p>© 2020 {STATIC.TITLE} <span class="d-none d-sm-inline-block"> - Crafted with <i class="mdi mdi-heart text-danger"></i> by <a href="https://aurax.io/">Aurax STE</a></span>.</p>
                </div>

            </div>
        )
    }
}

export default Login;